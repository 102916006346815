<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_status" v-model="selecttype_status" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>
            </v-col>
            <v-col lg="2" cols="5">
            <v-btn color="red" dark class="mb-2" @click="Recherche_clear()">
                Clear
            </v-btn>
            </v-col>


        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1" color="primary">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES TRANSACTIONS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Status de Compte</v-card-title>
                        <v-card-text>
                            <v-select :items="list_status" v-model="selectstatus" dense outlined label="Status de Compte">
                            </v-select>

                            <v-textarea v-model="commentaire" label="Commentaire" outlined></v-textarea>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_status" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Status paiement</v-card-title>
                        <v-card-text>
                          <v-select :items="list_type_status2" v-model="selecttype_status2" @change="onWantingStatusChange()"   dense outlined label="Status depot">
                          </v-select>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog_status=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="update_wanting_statut()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_wanting_adress" max-width="400">
                  <v-card>
                      <v-toolbar flat color="blue">
                          <v-toolbar-title >Mise à jour adresse retrait</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon dark @click="dialog_wanting_adress = false">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text>
                          <v-col cols="12">
                              <v-text-field v-model="maj_wanting_adress" label="Adresse" outlined>

                              </v-text-field>
                          </v-col>

                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green" dark @click="updateWantingAdresse()">Enregistrer</v-btn>
                          <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_wanting_money" max-width="400">
                  <v-card>
                      <v-toolbar flat color="blue">
                          <v-toolbar-title >Mise à jour Monnaie Retrait</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon dark @click="dialog_wanting_money = false">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text>
                          <v-col cols="12">
                              <v-text-field v-model="maj_wanting_money" label="Monnaie" outlined>

                              </v-text-field>
                          </v-col>

                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green" dark @click="updateWantingMoney()">Enregistrer</v-btn>
                          <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
                </v-dialog>

            </v-toolbar>
        </template>
        <template v-slot:item.user="{ item }">
            <p class="text-caption">ID: {{ item.user.id }}</p>
            <p class="text-caption">email: {{ item.user.email }}</p>
            <p class="text-caption">Nom: {{ item.user.personnel==null?'':item.user.personnel.noms }}</p>
            <p class="text-caption">Prenom: {{ item.user.personnel==null?'':item.user.personnel.prenoms }}</p>
            <p class="text-caption">Telephone: {{ item.user.personnel==null?'':item.user.personnel.tel }}</p>
            <p class="text-caption">Numero_whatsapp: {{ item.user.personnel==null?'':item.user.personnel.prefixe_number }}{{ item.user.personnel==null?'':item.user.personnel.tel_whatsapp }}</p>
        </template>


        <template v-slot:item.wanting_statut="{ item }">
            <div class="d-flex justify-center">

                <v-chip :color="item.wanting_statut =='CONFIRMED'?'green':'red'" dark>
                    {{ item.wanting_statut}}
                </v-chip>



                <v-btn icon @click="wanting_status(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-pencil
                  </v-icon>
                </v-btn>


            </div>

        </template>

        <template v-slot:item.having_statut="{ item }">
            <v-chip :color="item.having_statut =='CONFIRMED'?'green':'red'" dark>
                {{item.having_statut}}
            </v-chip>

        </template>



        <template v-slot:item.having_monaires.libelle_monnaie="{ item }">
            <v-alert color="#9B59B6" dark  border="left" prominent>
                <p class="text-caption">Monney: {{ item.having_monaires.libelle_monnaie }}</p>
                <p class="text-caption">Montant: {{ item.having_amount }}</p>
                <p class="text-caption">Adresse dépot: {{ item.having_adresse }}</p>
                <p class="text-caption">Reseau dépot: {{ item.havingNetwork }}</p>

                <v-btn icon @click="detailTrxHaving(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-eye
                  </v-icon>
                </v-btn>

            </v-alert>

        </template>

        <template v-slot:item.wanting_monaires.libelle_monnaie="{ item }">
            <v-alert color="#117A65" dark  border="left" prominent>
              <div class="d-flex ">
                <p class="text-caption">Monney: {{ item.wanting_monaires.libelle_monnaie }}</p>
                <v-btn icon @click="wanting_money_update(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-pencil
                  </v-icon>
                </v-btn>
              </div>

                <p class="text-caption">Montant: {{ item.wanting_amount }}</p>

                <div class="d-flex">
                  <p class="text-caption">Adresse retrait: {{ item.wanting_adresse }}</p>
                  <v-btn icon @click="wanting_adress_update(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-pencil
                  </v-icon>
                </v-btn>
                </div>

                <p class="text-caption">Reseau retrait: {{ item.wantingNetwork }}</p>

                <v-btn icon @click="detailTrxWanting(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-eye
                  </v-icon>
                </v-btn>
            </v-alert>

        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="teal" large class="mr-2">
                    mdi-eye
                </v-icon>
            </v-btn>

        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'
import axios from 'axios'
export default {
    name: "Gestion des clients",
    data: () => ({
        dialog: false,
        dialog2: false,
        dialog_status: false,
        dialog_wanting_adress: false,
        dialog_wanting_money: false,
        maj_wanting_adress: '',
        maj_wanting_money: '',
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '5px'

            },
            {

               text: "Id operation",
               align: "start",
               sortable: false,
               value: "id",
               divider: true,
               width: '25px'
           },
            {

                text: "Information client",
                align: "start",
                sortable: false,
                value: "user",
                divider: true,
                width: '250px'
            },
            {

               text: "Date operation",
               align: "start",
               sortable: false,
               value: "operation_date",
               divider: true,
               width: '100px'
           },

            {
                text: "Status de depot",
                value: "having_statut",
                divider: true,
                width: '2%'
            },
            {
                text: "Information du dépot",
                align: "start",
                sortable: false,
                value: "having_monaires.libelle_monnaie",
                divider: true,
                width: '150px'
            },
            {
                text: "Information du retrait",
                align: "start",
                sortable: false,
                value: "wanting_monaires.libelle_monnaie",
                divider: true,
                width: '150px'
            },

            {
                text: "Status de paiement",
                value: "wanting_statut",
                divider: true,
                width: '10px'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            nom: '',
            prenom: '',
            email: '',
            code: '',
            message: '',
        },

        list: [],
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],

        list_type_status: ['PENDING','STARTING', 'CONFIRMED', 'FAILED'],
        list_type_status2: ['PENDING','STARTING', 'CONFIRMED', 'FAILED'],

        selecttype_status: '',
        selecttype_status2: '',
        selecttype_recherche: '',

        valeur: '',
        etat: false,
        libelle_recherche: '',
        list_status: ['Valider', 'Bloquer', 'En attente'],

        id_transaction: '',
        wanting_statut: '',
        colonne: '',

    }),

    created() {
        this.readAll();

    },
    methods: {

        readAll: async function () {
            if(this.selecttype_recherche==''||this.selecttype_recherche==null){
                this.colonne="";
            }else{
                this.colonne=this.selecttype_recherche=='CODE CLIENT'?'key':'email';
            }
            if(this.selecttype_status==''||this.selecttype_status==null){
                this.wanting_statut="";
            }else{
                this.wanting_statut=this.selecttype_status;
            }

            let fd = new FormData();
            fd.append('colonne', this.colonne);
            fd.append('valeur', this.libelle_recherche);
            fd.append('wanting_statut', this.wanting_statut);
            const data = await api.createUpdatedata('backoffice/all-transaction', fd);
            this.list = data;
            //console.log(data);
            //console.clear();

        },

        Recherche_clear() {
            this.libelle_recherche = '';
            this.selecttype_recherche = '';
            this.wanting_statut = '';
            this.selecttype_status = '';

            this.readAll();
        },
        getitem_status: function (item) {
            this.id_transaction = item.id;
        },


        update_wanting_statut: async function () {
            let fd = new FormData();
            fd.append('id', this.id_transaction);
            fd.append('wanting_statut', this.wanting_statut);

             const data = await api.createUpdatedata('backoffice/transaction-status-wanting', fd);
             this.dialog_status = false;
            if (data.status == true) {
              api.sendTelegram('Transaction ['+this.id_transaction+'] updated with new wanting status ('+this.wanting_statut+')');
                this.readAll();
                this.showAlert(data.message, 'success');
                this.selecttype_status2 = '';
            } else if (data.status == false) {
                this.showAlert(data.message, 'error');
            }
        },

        updateWantingAdresse: async function () {
          let fd = new FormData();
          fd.append('id', this.id_transaction);
          fd.append('wanting_adresse', this.maj_wanting_adress);
          const data = await api.createUpdatedata('backoffice/transaction-update-wanting-adresse', fd);
          this.dialog_wanting_adress = false;
          if (data.status == true) {
              api.sendTelegram('Transaction ['+this.id_transaction+'] updated with new wanting adresse ('+this.maj_wanting_adress+')');
              this.readAll();
              this.showAlert(data.message, 'success');
              this.selecttype_status2 = '';
          } else if (data.status == false) {
              this.showAlert(data.message, 'error');
          }
        },

        updateWantingMoney: async function () {
          let fd = new FormData();
          fd.append('id', this.id_transaction);
          fd.append('wanting_currencie', this.maj_wanting_money);
          const data = await api.createUpdatedata('backoffice/transaction-update-wanting-money', fd);
          this.dialog_wanting_money = false;
          if (data.status == true) {
            api.sendTelegram('Transaction ['+this.id_transaction+'] updated with new wanting money ('+this.maj_wanting_money+')');
              this.readAll();
              this.showAlert(data.message, 'success');
              this.selecttype_status2 = '';
          } else if (data.status == false) {
              this.showAlert(data.message, 'error');
          }
        },


        detail: async function (item) {
            this.id = item.id;
            this.commentaire = item.observations;
            this.selectstatus = item.status == 1 ? 'Valider' : item.status == 2 ? 'Bloquer' : 'En attente';
            this.dialog = true;
        },



        detailTrxHaving : async function (item) {
          if(item.having_type == 'Local'){
            // open new tab with url to sms page with number
            this.$router.push({ name: 'sms', params: { numero: item.having_adresse }});
            //window.open('https://webservices.meta-change.io/app/serveurs/sms/'+item.having_adresse, '_blank');  // on remote server
            //window.open('http://localhost:8080/app/serveurs/sms/'+item.having_adresse, '_blank'); // on local server
          }else if(item.having_type == 'Dollars'){
            switch(item.having_currencie){
              case 'PAYEER':
                // open new tab with url to paypal page
                break;
              case 'PERFECT MONEY':
                // open new tab with url to paypal page
                break;
              case 'ADV CASH':
                // open new tab with url to paypal page
                break;
            }
          }else if(item.having_type == 'Crypto'){
              switch(item.havingNetwork){
                case 'BTC':
                  // open new tab with url to paypal page
                   window.open('https://www.blockchain.com/btc/tx/'+item.havingDepositId, '_blank');
                  break;
                case 'ERC20':
                  // open new tab with url to paypal page
                  window.open('https://etherscan.io/tx/'+item.havingDepositId, '_blank');
                  break;
                case 'LTC':
                  // open new tab with url to paypal page
                  window.open('https://live.blockcypher.com/ltc/tx/'+item.havingDepositId, '_blank');
                  break;
                case 'BEP20':
                  // open new tab with url to paypal page
                  window.open('https://bscscan.com/tx/'+item.havingDepositId, '_blank');
                  break;
                case 'TRC20':
                  // open new tab with url to paypal page
                  window.open('https://tronscan.org/#/transaction/'+item.havingDepositId, '_blank');
                  break;
              }
          }
        },

        detailTrxWanting : async function (item) {
          if(item.wanting_type == 'Local'){
            // open new tab with url to sms page with number
            this.$router.push({ name: 'sms', params: { numero: item.wanting_adresse }});
            //window.open('https://webservices.meta-change.io/app/serveurs/sms/'+item.wanting_adresse, '_blank'); // on remote server
            //window.open('http://localhost:8080/app/serveurs/sms/'+item.wanting_adresse, '_blank'); // on local server
          }else if(item.wanting_type == 'Dollars'){
            switch(item.wanting_currencie){
              case 'PAYEER':
                // open new tab with url to paypal page
                break;
              case 'PERFECT MONEY':
                // open new tab with url to paypal page
                break;
              case 'ADV CASH':
                // open new tab with url to paypal page
                break;
            }
          }else if(item.wanting_type == 'Crypto'){
              switch(item.wantingNetwork){
                case 'BTC':
                  // open new tab with url to paypal page
                  window.open('https://www.blockchain.com/btc/tx/'+item.wantingId, '_blank');
                  break;
                case 'ERC20':
                  // open new tab with url to paypal page
                  window.open('https://etherscan.io/tx/'+item.wantingId, '_blank');
                  break;
                case 'LTC':
                  // open new tab with url to paypal page
                  window.open('https://live.blockcypher.com/ltc/tx/'+item.wantingId, '_blank');
                  break;
                case 'BEP20':
                  // open new tab with url to paypal page
                  window.open('https://bscscan.com/tx/'+item.wantingId, '_blank');
                  break;
                case 'TRC20':
                  // open new tab with url to paypal page
                  window.open('https://tronscan.org/#/transaction/'+item.wantingId, '_blank');
                  break;
              }
          }
        },

        wanting_status: function (item) { // cette fonction est appelee lorsqu'on clique sur le bouton modifier du status de paiement
          this.id_transaction = item.id;
          this.dialog_status = true;
        },

        wanting_adress_update: function (item) { // cette fonction est appelee lorsqu'on clique sur le bouton modifier de l'adresse de retrait
          this.id_transaction = item.id;
          this.maj_wanting_adress = item.wanting_adresse;
          this.dialog_wanting_adress = true;
        },

        wanting_money_update: function (item) { // cette fonction est appelee lorsqu'on clique sur le bouton modifier de la monnaie de retrait
          this.id_transaction = item.id;
          this.maj_wanting_money = item.wanting_currencie;
          this.dialog_wanting_money = true;
        },

        onWantingStatusChange: function (item) { // cette fonction est appelee lorsqu'on change le status de paiement
          this.wanting_statut = this.selecttype_status2;
        },

        save: async function () {
            let fd = new FormData();
            fd.append('id', this.id);
            fd.append('observations', this.commentaire);
            fd.append('status', this.selectstatus == 'Valider' ? 1 : 'Bloquer' ? 2 : 0);
            const data = await api.createUpdatedata('backoffice/only-clients-update-status', fd);
            if (data.status == 200) {
                this.dialog = false;
                this.fetchData();
                this.showAlert(data.message, 'success');
            } else if (data.status == 400) {
                this.dialog = false;
                this.dialog2 = true;
                this.detail_Formulaire = {
                    email: data.data.email,
                    code: data.data.key,
                    message: data.message,
                }
            } else {
                this.dialog = false;
                this.showAlert(data.message, 'error');
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
